  .new-page {
    page-break-before: always;
  }

  .pdf_report {
	.titlePdf {
		text-align: center;
		margin:auto;
		margin-top: 2rem;
		margin-bottom: 2rem;
		padding: 1rem;
		background: #fff;
		color:$primary;
		font-weight: bold;
		// border-bottom: 2px solid $gray-400;
	  text-transform: uppercase;
	}
  
	.innerTitlePdf {
		margin-top: 2rem;
		margin-bottom: 1rem;
		color:$primary;
		font-weight: bold;
		border-bottom: 2px solid $gray-400;  	
		padding: 0.5rem 0;
	}
  
	.introPdf {
		max-width: 80%;
		font-size: 120%;
		margin:2rem auto;
	}	
  }

