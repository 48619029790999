

$draft : #ffb700;
// $draft_soft : #d5e5fa;
$draft_soft : lighten($draft, 45%);

$published: #00864e;
// $published_soft: #d8f6e9;
$published_soft: lighten($published, 65%);

$canceled: #626c79;
$canceled_soft: lighten($canceled, 50%);

// $ongoing: #8c3888;
$ongoing: #005eff;
// $ongoing_soft: #dec5dd;
$ongoing_soft: lighten($ongoing, 45%);

// $ongoing: #8c3888;
$planned:  $info;
// $ongoing_soft: #dec5dd;
$planned_soft: lighten($info, 55%);

// $ongoing: #8c3888;
$done: #00864e;
// $ongoing_soft: #dec5dd;
$done_soft: lighten($done, 60%);


$status_padding: 0.2rem 0.5rem;

.status-draft {
    color: darken($draft, 10%);
    i, &.badge {
        background-color: $draft_soft;
        color: darken($draft, 10%);
        padding: $status_padding;
        margin-right: 1rem;
    }
}


.status-published  {
    color: $published; 
    i, &.badge {
        background-color: $published_soft;
        color: $published;
        padding: $status_padding;
        margin-right: 1rem;
    }
}
.status-canceled, .status-over {
    color: $canceled; 
    i, &.badge {
        background-color: $canceled_soft;
        color: $canceled;
        padding: $status_padding;
        margin-right: 1rem;
    }
}

.status-ongoing {
    color: $ongoing; 
    i, &.badge {
        background-color: $ongoing_soft;
        color: $ongoing;
        padding: $status_padding;
        margin-right: 1rem;
    }
}

.status-planned {
    color: $planned; 
    i, &.badge {
        background-color: $planned_soft;
        color: $planned;
        padding: $status_padding;
        margin-right: 1rem;
    }
}

.status-done {
    color: $done; 
    i, &.badge {
        background-color: $done_soft;
        color: $done;
        padding: $status_padding;
        margin-right: 1rem;
    }
}
 

.btn-is_published {
    border: 1px solid $published;
    background-color: $published_soft;
    color: $published;

    &:hover {
        border: 1px solid $published;
        background-color: lighten($published_soft, 10%);
        color: $published;
    }
}

.btn-is_not_published {
    border: 1px solid $canceled;
    background-color: $canceled_soft;
    color: $canceled;

    &:hover {
        border: 1px solid $canceled;
        background-color: lighten($canceled_soft, 10%);
        color: $canceled;
    }
}

.btn-is_ongoing, .btn-is_ongoing_city , .btn-is_ongoing_epci{
    border: 1px solid $ongoing;
    background-color: lighten($ongoing_soft, 10%);
    color: $ongoing;

    &:hover {
        border: 1px solid $ongoing;
        background-color: $ongoing_soft;
        color: $ongoing;
    }
}

.btn-reset {
    border: 1px solid $draft;
    background-color: lighten($draft_soft, 10%);
    color: $draft;

    &:hover {
        border: 1px solid $draft;
        background-color: $draft_soft;
        color: $draft;
    }
}