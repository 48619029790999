.form-label, .col-form-label {
    font-weight: bold;
}

label.required,
legend.required,
.required-star {
    &:after {
        content: "*";
        color: $danger;
        margin-left: 0.4rem;
    }
}

.required.no-required-star:after {
    content: "";
}

.hide-required .required:after{
  content: "";
}

.submitted input:invalid {
    border-color: $danger;
}

.remember_to_save {
    display: none;
}

form.edited .remember_to_save {
    display: block;
}

.bloc-file.edited {
    .no-file {
        color: $info;
    }
}

// AUTO COMPLETE TS
.ts-control {
    border: none;
}

// CUSTOM CHECKBOX
.custom-checkbox {
    position: relative;
}
.custom-checkbox label {
    // padding: 0 0 0 3.5rem;
    position: relative;
    font-weight: normal;
    color: #fff;
    display: flex;
    width: 100%;
    justify-content: center;
    cursor: pointer;
}

.custom-checkbox label:before,
.custom-checkbox label:after {
    font-family: bootstrap-icons !important;
    justify-content: center;
    line-height: 1;
    text-align: center;
    color: $primary;
    width: 100%;
    font-size: 1.2rem;
}

.custom-checkbox label:before {
    content: "\F584";
    display: flex;
}

.custom-checkbox label:after {
    // content: "\F271";
    content: "\F26D";
    display: none;
}

.custom-checkbox label:hover {
    /*background: #f8f9fa;*/
    /*color: #101010;*/
    /*box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2), inset 0 -3px 0 rgba(0, 0, 0, 0.32);*/
}
.custom-checkbox label:active {
    /*transform: translateY(2px);*/
    /*box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2), inset 0px -1px 0 rgba(0, 0, 0, 0.22);*/
}

.custom-checkbox input[type="checkbox"] {
    display: none;
}

.custom-checkbox input[type="checkbox"]:checked + label:after {
    display: flex;
}
.custom-checkbox input[type="checkbox"]:checked + label:before {
    display: none;
}
.custom-checkbox input[type="checkbox"]:checked + label:hover {
    /*background: #29b765;*/
    /*color: #e2e2e2;*/
}

.custom-checkbox.custom-all {
    label:before {
        content: "\F4FD";
    }

    label:after {
        content: "\F2E9";
    }
}

// label.required, legend.required, .required-star {
//     &:after {
//         content: "*";
//         color: $danger;
//         margin-left: 0.4rem;
//     }
// }

// .required.no-required-star:after {
//     content: "";
// }

.custom-radio {
    display: flex;
    .form-check {
        padding-left: 0;
        padding-right: 1rem;
    }
    label {
        border: 1px solid $primary;
        padding: 0.3rem 1rem;
        border-radius: 20px;
        white-space: nowrap;
        cursor: pointer;

        &:active {
            background-color: $primary;
        }

        &.required {
            &:after {
                display: none;
            }
        }

        &:hover {
            background-color: lighten($color: $primary, $amount: 70);
        }

    }
    input[type="radio"] {
        display: none;
        &:checked + label {
            background-color: $primary;
            color: $white;
        }
        &:checked + label:hover {
            
        }
    }
}

// <div class="checkbox-wrapper">
//   <label class="checkbox">
//     <input class="checkbox__trigger visuallyhidden" type="checkbox" />
//     <span class="checkbox__symbol">
//       <svg aria-hidden="true" class="icon-checkbox" width="28px" height="28px" viewBox="0 0 28 28" version="1" xmlns="http://www.w3.org/2000/svg">
//         <path d="M4 14l8 7L24 7"></path>
//       </svg>
//     </span>
//     <p class="checkbox__textwrapper">Checkbox</p>
//   </label>
// </div>


  .checkbox-wrapper {
    --s-xsmall: 0.625em;
    --s-small: 1.2em;
    --border-width: 1px;
    --c-primary: #5F11E8;
    --c-primary-20-percent-opacity: rgb(95 17 232 / 20%);
    --c-primary-10-percent-opacity: rgb(95 17 232 / 10%);
    --t-base: 0.4s;
    --t-fast: 0.2s;
    --e-in: ease-in;
    --e-out: cubic-bezier(.11,.29,.18,.98);
  }

  .checkbox-wrapper .visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .checkbox-wrapper label {
    display: flex;
    // align-items: center;
    // justify-content: flex-start;
    cursor: pointer;
  }
  .checkbox-wrapper .checkbox + .checkbox {
    margin-top: var(--s-small);
  }
  .checkbox-wrapper .checkbox__symbol {
    display: inline-block;
    display: flex;
    margin-right: calc(var(--s-small) * 0.7);
    border: var(--border-width) solid var(--c-primary);
    position: relative;
    border-radius: 0.1em;
    width: 1.5em;
    height: 1.5em;
    transition: box-shadow var(--t-base) var(--e-out), background-color var(--t-base);
    box-shadow: 0 0 0 0 var(--c-primary-10-percent-opacity);
  }
  .checkbox-wrapper .checkbox__symbol:after {
    content: "";
    position: absolute;
    top: 0.5em;
    left: 0.5em;
    width: 0.25em;
    height: 0.25em;
    background-color: var(--c-primary-20-percent-opacity);
    opacity: 0;
    border-radius: 3em;
    transform: scale(1);
    transform-origin: 50% 50%;
  }
  .checkbox-wrapper .icon-checkbox {
    width: 1em;
    height: 1em;
    margin: auto;
    fill: none;
    stroke-width: 3;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
    color: var(--c-primary);
    display: inline-block;
  }
  .checkbox-wrapper .icon-checkbox path {
    transition: stroke-dashoffset var(--t-fast) var(--e-in);
    stroke-dasharray: 30px, 31px;
    stroke-dashoffset: 31px;
  }
  .checkbox-wrapper .checkbox__textwrapper {
    margin: 0;
  }
  .checkbox-wrapper .checkbox__trigger:checked + .checkbox__symbol:after {
    -webkit-animation: ripple-33 1.5s var(--e-out);
            animation: ripple-33 1.5s var(--e-out);
  }
  .checkbox-wrapper .checkbox__trigger:checked + .checkbox__symbol .icon-checkbox path {
    transition: stroke-dashoffset var(--t-base) var(--e-out);
    stroke-dashoffset: 0px;
  }
  .checkbox-wrapper .checkbox__trigger:focus + .checkbox__symbol {
    box-shadow: 0 0 0 0.25em var(--c-primary-20-percent-opacity);
  }

  @-webkit-keyframes ripple-33 {
    from {
      transform: scale(0);
      opacity: 1;
    }
    to {
      opacity: 0;
      transform: scale(20);
    }
  }

  @keyframes ripple-33 {
    from {
      transform: scale(0);
      opacity: 1;
    }
    to {
      opacity: 0;
      transform: scale(20);
    }
  }

