body {
    // overflow-y: scroll;
}


body.front {

    background-size: 100%;
    background-position: center 130px;
    background-repeat: no-repeat;

    .main-header {
        background-color: #fff !important;
        border-bottom: none !important;

        .main-header-title {
            // margin: auto;
        }

        .img-branding {
            max-width: 200px;
            // margin-top: 1rem;
            // transform: translateX(-10px);
        }
    }

    .footer {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        text-align: center;
        background-color: $light;
        color: $body-color;
        z-index: 10000;
    }


}



a, button {
    @include transition(all 0.4s ease);

    &:hover {
        @include transition(all 0.4s ease);
    }
}

.relative {
    position: relative;
}

[data-bs-toggle="tooltip"],
[data-bs-toggle="popover"]
{
    cursor: pointer;
}

.popover {
    font-weight: 500;
}
.popover-header {
    font-weight: normal;
    font-size: 110%;
}

.w1200, .xl_size {
    max-width: 1200px;	
    margin:auto
}

.large_size {
    max-width: 1050px;
    margin:auto
}

.medium_size {
    max-width: 950px;
    margin:auto
}

.small_size {
    max-width: 850px;
    margin:auto
}