.swal2-container.swal2-backdrop-show,
.swal2-container.swal2-noanimation {
    background: rgba(lighten($primary, 55%),30%) !important;
}

.swal2-show {
    // animation: swal2-show 1s !important;
    // animation-timing-function: ease-out  !important;

    animation: bounce; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 2s; /* don't forget to set a duration! */

}

.swal2-hide {
    // animation: swal2-hide 1s !important;
    // animation-timing-function: ease-out  !important;
}

.swal2-modal {
    background-color: $white;
    // border: 1px solid $primary !important;
    // border-radius: 5px !important;
    position: relative !important;
    // box-shadow: 3px 3px 5px rgba(#000000,40%);
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2) !important;
    border-radius: 0px  !important;
}

.swal2-confirm {
    background-color: $primary !important;
    color: $white !important;
    border-color: $primary !important;
    font-weight: bold !important;

    &:hover {
        background-color: $white !important;
        color: $primary !important;
        border-color: $primary !important;        
    }
}

$cancel-color : $gray-600;
.swal2-cancel {
    background-color: $cancel-color !important;
    color: $white !important;
    border-color: $cancel-color !important;
    font-weight: bold !important;

    &:hover {
        background-color: $white !important;
        color: $cancel-color !important;
        border-color: $cancel-color !important;        
    }
}

.swal2-icon {
    // width: 4em !important;
    // height: 4em !important;
    transform: scale(0.7);
    border-width: 1px !important;
    margin: 1em auto 0em !important;
    .swal2-icon-content {
        font-size: 2.5rem !important;
    }
    // height: auto !important;
    // width: auto !important;
    line-height: 1.4 !important;

    &.swal2-info, &.swal-confirm {
        border-color: lighten($primary, 50%) !important;
        color: $primary !important;
      }
}

.swal2-title {
    font-size: 1.3rem !important;
    padding: 1rem 1em 1rem !important;
}

.swal2-html-container {
    text-align: initial !important;
    margin: 1.5rem 1.5rem 2rem !important
}


.swal2-cancel {
    font-weight: normal !important;
}

.swal2-confirm {
    font-weight: normal !important;
}

.swal2-textarea {
    min-height: 250px;
}