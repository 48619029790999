.draggable-item {
    border: 1px solid #ccc;
    // background-color: #f9f9f9;

    &:first-child {
        .moveUp {
            display: none;
        }
    }
    &:last-child {
        .moveDown {
            display: none;
        }
    }
}

.draggable-item.drag-over {
    // background-color: #ffdd0077;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.10);
    border: 1px dashed $dark;
}

.draggable-icon {
    cursor: move;
}

/* Optional: Style for dragging the item */
.draggable-item.on_drag {
    opacity: 0.5;
}
