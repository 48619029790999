
.btn-valide {

    background-color: $action;
    color: $primary;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.12);

    &:hover {
        color: $primary  !important;
        box-shadow: 0px 0px 7px 4px rgba(0, 0, 0, 0.12);
    }

}

.btn-primary, .button-submit-demande {

    background-color: $primary;
    color: $white;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.12);

    &:hover {
        color: $primary  !important;
        background-color: $white;
        box-shadow: 0px 0px 7px 4px rgba(0, 0, 0, 0.12);
    }
}
    
.button-submit-demande {
    font-size: 1.4rem;
    padding: 1rem 2rem;
    line-height: 1;
    border-radius: 40px;
}

// Dropdown 

.hide_dropdown_after {
    &:after {
        display: none;
    }
}