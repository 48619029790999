.aside-menu {
    padding: 0;
    margin: 0;

    list-style: none;

    .menu-header {
        font-size: 1.2rem;
        // padding-bottom: 1rem;
    }

    .menu-submenu {
        padding-left: 0.5rem;
        list-style: none;
        font-size: 1rem;

        .menu-item {
            // padding: 0.3rem 0;
            padding: 0;
        }
    }

    .active {
        font-weight: bold;
    }

    a {
        text-decoration: none;
    }

    .accordion-header {
        font-weight: bold;
    }
    .accordion-button {
        font-weight: bold;
        // color: $white;
        // background-color: $primary;
        // padding: 1rem 0.8rem;
        padding: 0;
    }
    .accordeon-body {
        // padding: 0.7rem 0.5rem;
        padding: 0;
    }


}

.side-nav {
    padding-left: 0;
    list-style-type: none;
    margin-top: 0.5rem;
    z-index:2;

    .side-nav-link {
        position: relative;
        color: #000;
        display: block;
        padding: 10px 40px 10px 20px;
        font-size: 1rem;
        font-weight: bold;
        position: relative;
        -webkit-transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
        transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
        list-style: none;
        text-decoration: none;
        margin-top: 1rem;

        &:not(.collapsed){
            color: $primary;
          }

        &:not(.collapsed):after {
            background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23212529%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
            transform: rotate(-180deg);
          }

        &:after {
            position: absolute;
            top:14px;
            right:10px;
            // transform:translateY(-50%);
            width: 1.25rem;
            height: 1.25rem;
            margin-left: auto;
            content: "";
            background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23004e5f%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
            background-repeat: no-repeat;
            background-size: 1.25rem;
            transition: transform 0.2s ease-in-out;
          }
    }

    .side-nav-title {
        padding: 12px 20px;
        letter-spacing: 0.05em;
        pointer-events: none;
        cursor: default;
        font-size: 0.6875rem;
        text-transform: uppercase;
        color: #8391a2;
        font-weight: 700;
    }

    ul {
        padding-left: 0;
        list-style-type: none;
    }

    .side-nav-forth-level li .side-nav-link,
    .side-nav-forth-level li a,
    .side-nav-second-level li .side-nav-link,
    .side-nav-second-level li a,
    .side-nav-third-level li .side-nav-link,
    .side-nav-third-level li a {
        padding: 8px 30px 8px 40px;
        color: $primary;
        display: block;
        position: relative;
        -webkit-transition: all 0.4s;
        transition: all 0.4s;
        font-size: 0.89rem;
    }

    .side-nav-item-link.active {
        font-weight: bold;
    }

}
