.card-fieldset {
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.10);
    border-radius: 6px;
    border: 0;
    border: 1px solid $gray-200;
    // background-color: $gray-100;
    @include transition(all);

    .fiedlset-title {
        color: $primary;
    }

}

body.admin {
    .card-fieldset {
        &.edited {
            border-color: $info;
            @include transition(all);
        }
    }   
}
