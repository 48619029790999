.app-table-list {
    th {
        background-color: transparentize($primary, 0.88) !important;
        padding: 0.7rem;
    }
    tr {
        &:hover {
            background-color: #f4f4f4;
        }
    }
    td {
        padding: 0.5rem 0.5rem;

        &.td-checkable {
            cursor: pointer
        }
    }
}

#navbarBulkActions .nav-link {
    cursor:pointer;
}

tr.active {
    background-color: transparentize($success, 0.92) !important;
}