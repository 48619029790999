.branding {
    color: $white;
    text-decoration: none;
    font-weight: bold;

    &:hover {
        color: $light;
    }
}

.img-branding {
    height: 60px;
    width: auto;
}