.priority-scale .form-check {
    display: inline-block;
}

.priority-scale input[type="radio"] {
    display: none;
}
.priority-scale input[type="radio"] + label {
    padding: 0.3rem 0.8rem;
    border: 1px dashed #ccc;
    text-align:center;
    border-radius: 3px;
    cursor:pointer;
}
.priority-scale input[type="radio"] + label:after {
    display:none
}
.priority-scale input[type="radio"]:checked + label {
    border: 1px solid #000;
    background-color: lighten($primary, 65%);;
    font-weight:bold;
}