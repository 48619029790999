.auto-hide {
    animation: cssHideAnimation 4s forwards;
	opacity: 1;
}
@keyframes cssHideAnimation {
	0%   {opacity: 1;height:auto;width:auto;}
    80%  {opacity: 1;height:auto;width:auto;}
    100% {opacity: 0;height:0;width:0;overflow: hidden;}
}

.messages {
    position: fixed;
    right:2rem;
    bottom: 2rem;
    z-index: 10000;
}

.wrapper {
    display: flex;
    flex-direction: column;
    // flex-wrap: wrap;
    // padding:1rem;
    height: 100vh;
    width: 100%;
    // overflow: hidden;
}

.header {
    // padding:1rem;
    width: 100%;
    order: 1;

    
    #navbarUserActions .dropdown-menu {
        z-index: 10000;
    }

}

.main_title,
.main_title a {
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: none;
    color: #ffffff;
}

.main {
    flex: auto;
    // padding:1rem;
    order: 3;
    display: flex;
    flex-direction: row;
    overflow-y: auto;
    background: $the-gray;

    .main-header {
        background-color: $white;
        z-index: 1009;
    }

    .aside {
        background: $white;
        // padding:1rem;
        width: 280px;
        order: 1;
        height: 100%;
        // overflow-y: auto;
        display: flex;
        flex-direction: column;
        z-index:2;
        position: relative;

        .select-project {
            position:absolute;
            bottom: 0;
            left: 0;
            z-index:2;
            width: 100%;
    
        }
    }

    .main-content {

        flex: auto;
        order: 2;
        height: 100%;
        overflow-y: auto;
        position: relative;

        .main-content-padding {
            padding: 1rem;
        }

        // .loading {
        //     position: absolute;
        //     background: rgba($color: #ffffff, $alpha: 1);
        //     height: 100%;
        //     width: 100%;
        //     z-index: 100;
        //     transform: translate(-1rem, -1rem);
        //     padding: 6rem 3rem;
        // }
    }
}


.modal-open .main-content {
    // overflow: hidden;
    // padding-right: 17px !important;
}

footer.footer {
    background: $dark;
    text-align: center;
    color: #ffffff;
    width: 100%;
    padding: 0.5rem;
    order: 4;
    font-size: 80%;
    z-index: 10000;
}

.aside .menu {
}


.pointer {
    cursor: pointer;
}